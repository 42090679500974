import React from "react"
import {graphql, Link, StaticQuery, useStaticQuery} from "gatsby"

import Layout from "../components/layout"
import Img from "gatsby-image"
import Meta from "../components/meta"
import teamBgImg from "../images/about_bg_1.svg";
import linkedinIconImg from "../images/linkdin.png";
import ourStoryImg from "../images/blue_layer.png";
import samuraiImg from "../images/ip1.png";
import nielsenImg from "../images/ip2.png";
import tiproyecImg from "../images/ip3.png";
import gruporepulloImg from "../images/ip4.png";
import bseedImg from "../images/ip5.png";
import RequestDemoMiniForm from "../components/request-demo-mini-form";

const Homepage = ({data, location}) => {
    return data && (
        <Layout location={location}>
            <Meta title="About us"/>
            <div className="banner about_banner">
                <div className={'wrapper'}>
                    <div className="banner_content">
                        <h1><span>Gain control over the incentivization process</span><br/> motivate and engage your
                            sales teams</h1>
                    </div>
                </div>
            </div>
            <div className="about_text">
                <div className="wrapper">
                    <h2>
                        Make the work environment of your salespeople working from the office or from home more
                        empowering and productive.
                    </h2>
                    <Link to={'/book-a-demo'} className="btn">Contact Us</Link>
                </div>
            </div>
            <div className="management_team">
                <h2>Management Team</h2>
                <div className="team_bg_svg">
                    <img src={teamBgImg} alt="team background"/>
                </div>
                <div className="wrapper">
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Viki Glam</h3>
                            <h4>COO & Co Founder</h4>
                            <p>Track-record in scaling start-ups. Expert in Social Media, Tech and Education. Founder of
                                Footybase, Co-Founder of Mimshak, Founder and CEO of hug.co.il and Mekusharim (both
                                acquired).</p>
                            <a href="https://www.linkedin.com/in/vikiglam/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Dror Cohen</h3>
                            <h4>CEO & Co Founder</h4>
                            <p>Owner of Improve Business Consulting. Expert in enhancing business performance and
                                developing innovative research-based concepts for leadership, management, sales &
                                customer service.</p>
                            <a href="https://www.linkedin.com/in/dror-cohen-b77aa791/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Moshe Teter</h3>
                            <h4>CTO & Co Founder</h4>
                            <p>Tech-savvy fullstack software architect and web expert. Previously CTO and Co-Founder of
                                Lessoons, Team Leader at RealCommerce and Founder of Mabsut.</p>
                            <a href="https://www.linkedin.com/in/moshe-teter-72988523/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Ziv Shalev</h3>
                            <h4>Consultant For The International Market</h4>
                            <p>Expert in call center performance optimization. Founder & CEO @ Ziv Shalev Consulting,
                                Founder @ Aspire call center technologies, Former CEO @ MyBills.co.il, Former CEO @
                                Teleall contact centers.</p>
                            <a href="https://www.linkedin.com/in/zivshalev/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Shlomi Ashkenazi</h3>
                            <h4>Global Sales Director</h4>
                            <p>Partner at Pango Polska. Co-Founder of Headvantage, EYEDO and Humanix. Formerly Nextcom
                                CEO and VP Sales and Customer Relations at HOT.</p>
                            <a href="https://www.linkedin.com/in/shlomi-ashkenazi-66a03875/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>
                    <div className="col">
                        <div className="img_col"/>
                        <div className="text_col">
                            <h3>Lirone Glikman</h3>
                            <h4>Consultant for Global Business Development & Marketing</h4>
                            <a href="https://www.linkedin.com/in/lironeglikman/" target={'_blank'}>
                                <img src={linkedinIconImg} alt="LinkedIn"/>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div className="our_story">
                <img src={ourStoryImg} alt="background"/>
                <div className="wrapper">
                    <h2>Our story</h2>
                    <div className="inner">
                        <div className="col">
                            <p>
                                Enerjoy was founded by experienced sales managers, an organizational consultant, an
                                AI expert and a team of psychologists and behavioral economics experts who
                                understood a simple but radical truth. When it comes to motivation, one size does
                                not fit all. We set out to transform sales team motivation at the level of each
                                individual.
                            </p>
                            <p>
                                Today, Enerjoy helps companies achieve tangibly better performance and productivity
                                in their sales and customer service teams by increasing and sustaining staff
                                motivation, in a personalized way.
                            </p>
                        </div>
                        <div className="col">
                            <p>
                                We do this through an AI-powered personalized motivation platform based on a deep
                                understanding of personal psychology, team dynamics and organizational behavior.
                            </p>
                            <p>
                                We believe in people, and we will be happy to get to know you, so feel free to share
                                your thoughts with us.
                            </p>
                            <Link to={'/book-a-demo'} className="btn">Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="media">
                <h2>In The Media</h2>
                <div className="wrapper">
                    <a className="col ShadowboxVideo" href="https://www.youtube.com/embed/QQvDGfh2m1s"
                       data-rel="shadowbox;player=iframe;width=800;height=500">
                        <div className="img_col"/>
                        <div className="text">
                            <h3>Innovators Show</h3>
                            <h4>Nasdaq studios</h4>
                        </div>
                    </a>
                    <a href="javascript:void(0)" className="col">
                        <div className="img_col"/>
                        <div className="text">
                            <h3>Pitch Night</h3>
                            <h4>SAP next gen, NYC</h4>
                        </div>
                    </a>
                    <a className="col ShadowboxVideo" href="https://www.youtube.com/embed/C5ku6-Yr5v4"
                       data-rel="shadowbox;player=iframe;width=800;height=500">
                        <div className="img_col"/>
                        <div className="text">
                            <h3>Mind Your Business</h3>
                            <h4>weekly radio show on New York</h4>
                        </div>
                    </a>
                </div>
            </div>
            <div className="investor_partners">
                <h2>Investors and Partners</h2>
                <div className="wrapper">
                    <a href="javascript:void(0)"><img src={samuraiImg} alt="samurai incubate"/></a>
                    <a href="javascript:void(0)"><img src={nielsenImg} alt="nielsen"/></a>
                    <a href="javascript:void(0)"><img src={bseedImg} alt="b-seed"/></a>
                    <a href="javascript:void(0)"><img src={tiproyecImg} alt="TIProyec"/></a>
                    <a href="javascript:void(0)"><img src={gruporepulloImg} alt="grupo repullo"/></a>
                </div>
            </div>
            <div className="card about_card">
            </div>
            <div className="salse_col">
                <div className="wrapper">
                    <div className="in_sales cf">
                        <div className="text_right">
                            <h3>Want to increase your sales?</h3>
                        </div>
                        <div className="con_mail">
                            <RequestDemoMiniForm/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Homepage;

export const fluidImage = graphql`
    fragment fluidImage on File {
        childImageSharp {
            fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
                ...GatsbyImageSharpFluidLimitPresentationSize
            }
        }
    }
`;

export const query = graphql`
    query {
        facesMobile: file(relativePath: { eq: "faces-mobile@2x.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 959, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        facesDesktop: file(relativePath: { eq: "faces-line.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        graphic1: file(relativePath: { eq: "graphic-hp1.png" }) { ...fluidImage }
        graphic2: file(relativePath: { eq: "graphic-hp2.png" }) { ...fluidImage }
        graphic3: file(relativePath: { eq: "graphic-hp3.png" }) { ...fluidImage }
    }
`;